import * as React from 'react';
import { Link } from 'gatsby';
import '../index.scss';
import {c} from '../utils';
import Arrow from '../components/Arrow';
import {Helmet} from 'react-helmet';
import {useIsPastScroll} from '../hooks/useIsPastScroll';
import MadeWithLove from '../components/MadeWithLove';

// markup
const Registry = () => {
  const scrolled = useIsPastScroll();
  
  return (
    <main className='not-found'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Registry | The Cantú's</title>
        <script>{'!function(e,t,n){var s,a=e.getElementsByTagName(t)[0];e.getElementById(n)||(s=e.createElement(t),s.id=n,s.async=!0,s.src="https://widget.zola.com/js/widget.js",a.parentNode.insertBefore(s,a))}(document,"script","zola-wjs");'}</script>
      </Helmet>
      <Link to={'/#more-info'} className={c('back-arrow', scrolled && 'shadow')}>
        <Arrow /> BACK
      </Link>
      <h1 style={{ marginTop: 80 }}>Registry</h1>

      <a className="zola-registry-embed"
        href="www.zola.com/registry/brookeandviviano"
        data-registry-key="brookeandviviano">Our Zola Wedding Registry</a>

      {/*<Toggle style={{ marginTop: '20vh'}}/>*/}
    </main>
  );
};

export default Registry;
